.ant-card.notification-card {
    width: 100%;
    margin: 15px 0px 30px 0px;
    border-radius: 5px;
    padding-top: 10px;
}

.notification-card-header {
    color: white;
    font-weight: bold;
    font-size: 0.8rem;

    height: 25px;
    width: 200px;
    border-radius: 5px;

    position: absolute;
    top: -10px;
    left: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
}