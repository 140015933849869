.ant-badge.notifications-list {
    margin-right: 20px;
    margin-left: 10px;
    cursor: pointer;
}

.notifications-icon {
    font-size: 1.2rem;
}

.notifications-icon:hover {
    color: #1890ff;
}

.notifications-dropdown-header {
    background-color: #081f2d;
    width: 100%;
    height: 40px;
    padding: 10px;
    padding-right: 20px;
    margin-bottom: 5px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.notifications-dropdown-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
}