.mark {
  cursor: pointer;

  background-color: #f6ffed;
  border: 1px solid #b7eb8f;

  border-radius: 5px;
  margin: 0;
  padding: 0.4em 1em;

  &.selected {
    border: 1px solid red;
  }

  &.highlighted {
    border: 1px dashed #00aeff;
  }

  &.relation {
    cursor: crosshair;
  }
}

.delete {
  color: #1890ff;
  padding-top: 0.5em;
  padding-left: 1em;
  line-height: 1.8em;
}

.row {
  display: flex;
  margin-bottom: 0.5em;
}
