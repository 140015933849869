.upload-container {
    .upload-element {
        .upload-minFiles {
            font-weight: bold;
        }

        .ant-upload-list-item {
            cursor: pointer;

            .ant-upload-list-item-card-actions-btn {
                opacity: 1
            }

            .anticon-spin {
                display: none;
            }

            .ant-progress-line {
                display: none;
            }
        }
    }

    @media (max-width: '480px') {

        .upload-element .ant-upload-list-text .ant-upload-span,
        .ant-upload-list-picture .ant-upload-span {
            display: grid;
            grid-template-columns: 5% 85% 5%;
        }
    }
}



@media (max-width: '1224px') {
    .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-header {
        padding: 10px;
    }

    .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-body {
        padding: 24px 0px;

    }
}



@media (max-width: '1224px') {
    .pdf-page {
        width: 100%;
    }
}

.pagination-buttons {
    display: flex;
    justify-content: center;
    align-items: baseline !important;
    margin-top: 1rem;
}

.pdf-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
