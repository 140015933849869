.ls-error {
  padding: 10px 15px;
  display: block;
  border-radius: 3px;
  color: rgb(119, 27, 4);
  border: 1px solid rgb(230, 138, 110);
  background-color: rgb(255, 193, 174);

  & + & {
    margin-top: 10px;
  }
}
