.nav-container {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 1;

    .nav-links-container {
        flex-basis: 20%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .pm-menu {
            justify-content: flex-end;
        }
        .ct-menu {
            width: 285px;
        }
    }

    .nav-logo {
        max-width: 50px;
    }

    .nav-logo-text {
        font-size: 1rem;
        color: black;
        margin-left: 10px;
    }
}