@import "../../LSF/assets/styles/global.scss";

.ant-list-item-action {
  margin-left: 10px !important;
}

.segment {
  position: sticky;
  top: 1rem;
  // hack for flex to prevent text block from glowing enormously
  min-width: 0;
}

.editor {
  min-width: 320px;
  margin: 0 auto;

  @include respond("phone") {
    width: 300px;
    min-width: 300px;
  }
}

.editorfs {
  position: absolute;
  background: white;
  top: 0;
  left: 0;
  width: 100%;
  padding: 2em;
}

.menu {
  width: 320px;
  min-width: 320px;
  display: flex;
  flex-flow: column;

  &>div {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include respond("phone") {
    margin-left: 0;
    margin-top: 20px;
  }
}

.menubsp {
  display: flex;
  flex-direction: row;
  margin-top: 2em;

  &>div {
    margin-right: 2em;
    width: 33.3%;

    &:last-child {
      margin-right: 0;
    }
  }
}

.menu:empty {
  width: 0;
  min-width: auto;
}

.content {
  // border: 1px solid #e8e8e8;
  padding: 10px 20px;

  @media (max-width: 640px) {
    padding: 0px;
    border: 0;
  }
}

.common {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.commonbsp {
  display: block;
}

.pins {
  width: 100%;
  display: flex;
}

.pins div {
  text-align: right;
}

.pinsright {
  width: 320px;
  min-width: 320px;
}

@media only screen and (max-width: 768px) {
  .common {
    display: initial !important;
  }
}

.ls-common {
  display: flex;
  width: 100%;
}

.ls-main {
  flex: 3;
}

.ls-menu {
  flex: 1;
  margin-left: 10px;
}

.ant-collapse.collapse-container-completions {
  border: none;
  background-color: white;
}

.collapse-panel-completions>.ant-collapse-header {
  padding: 5px 0px 0px 10px !important;
  margin: 0;
}

.center-cards {
  display: flex;
  align-items: center;
}