.sorter-style .ant-collapse-content-box {
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    margin-bottom: 30px;
}

.contributor-controls {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.pagination-style {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.project-card-style {
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    width: 80vw;
    padding: 10px;
}