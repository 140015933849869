[data-theme='compact'] .users-collapse .users-panel,
.users-collapse .users-panel {
  background: white;
  border-bottom: 1px solid #eee;
  overflow: hidden;
}

[data-theme='compact'] .users-collapse .users-panel:first-of-type,
.users-collapse .users-panel:first-of-type {
  border-top: 1px solid #eee;
}

[data-theme="dark"] .users-collapse .users-panel {
  background: rgba(255,255,255,0.04);
  border: 0px;
}