.card-title-style {
    font-size: '1.2rem';
}

.internal-card-style {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.auth-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.mobile-card-style {
    width: 90vw;
    margin-top: 25%;
}

.mobile-card-header {
    display: flex;
    justify-content: center;
    margin-top: 15%;
    font-size: xx-large;
}

.mobile-login-internal-card {
    width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.registeration-mobile-footer {
    display: flex;
    align-items: center;
    flex-direction: column;
}