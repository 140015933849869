.ant-layout-content.content {
  background: #fff;

  &--align-vertically {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}
