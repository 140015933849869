[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  background: white;
  //   margin-bottom: 24px;
  border: 0px;
  overflow: hidden;
}

[data-theme="dark"] .site-collapse-custom-collapse .site-collapse-custom-panel {
  background: rgba(255, 255, 255, 0.04);
  border: 0px;
}

.completions-table .ant-table {
  font-size: 11px;
}