.buttons {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.skipped {
  font-size: 1.4em;
  min-width: 2em;
}

.completioncard {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  & > div:first-child {
    flex-grow: 1;
  }
}

.completion {
  padding: 1em !important;
  cursor: pointer;

  &_selected {
    background: rgba(0, 0, 0, 0.05);
  }
}

.title {
  display: flex;
  align-items: center;
  color: #1890ff;
  font-weight: bold;

  h3 {
    margin: 0;
  }
}

.titlespace {
  justify-content: space-between;
}

.itembtns {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.draftbtn {
  padding: 0;
  border: 0;
  vertical-align: -0.5px;
  height: auto;
}

.card{
  margin-bottom: 10px;
}