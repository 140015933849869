.ant-btn.btn-success {
    background-color: #2CB280;
    color: white;
    border: 0;
}

.ant-btn.btn-success:hover {
    background-color: #2CB280;
    filter: brightness(120%)
}

.ant-btn.btn-success:disabled {
    filter: brightness(100%)
}

.ant-btn.btn-success:active,
.ant-btn.btn-success:focus {
    background-color: #2CB280;
}

.ant-btn.btn-secondary {
    background-color: #CE93D8;
    color: white;
    border: 0;
}

.ant-btn.btn-secondary:hover {
    background-color: #CE93D8;
    filter: brightness(120%)
}

.ant-btn.btn-secondary:disabled {
    filter: brightness(100%)
}

.ant-btn.btn-secondary:active,
.ant-btn.btn-secondary:focus {
    background-color: #CE93D8;
}

.ant-btn-link.btn-link {
    text-align: left;
    width: 20%;
}

.ant-btn-link.btn-link:hover {
    font-style: oblique;
    background-color: #f5f5f5;
}


.ant-btn-link.header-buttons:hover {
    border-bottom: thin solid #081f2d;
}

.water-mark {
    opacity: 0.07;
    font-size: 150px;
    font-weight: 600;
    position: absolute;
    top: -5%;
    user-select: none;
    filter:
        drop-shadow(1em 2em #000) drop-shadow(1em 2em #000) drop-shadow(1em 2em #000) drop-shadow(1em 2em #000) drop-shadow(1em 2em #000);
}